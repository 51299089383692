import React, { useState, useEffect, useCallback } from 'react';
import Calendar from 'react-calendar';
import '../BookingCalendar.css';
import axios from 'axios';
import { addMonths, addMinutes, format, addDays, startOfDay, setHours, setMinutes } from 'date-fns';
import { fi } from 'date-fns/locale';
import ModalComponent from './ModalComponent';

const TIME_BLOCKS = [
    "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00"
];

const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6;
};

function BookingCalendar() {
  const today = startOfDay(new Date());
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [message, setMessage] = useState('');
  const [showSummary, setShowSummary] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [occupiedSlots, setOccupiedSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const [companyName, setCompanyName] = useState('');
  const [companyIndustry, setCompanyIndustry] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [companyIndustryError, setCompanyIndustryError] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  const [otherSource, setOtherSource] = useState('');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [step, setStep] = useState(1);

  const meetingDuration = 45;
  const minDate = addDays(new Date(), 2);
  const maxDate = addMonths(new Date(), 1);
  
  const tileDisabled = ({ date }) => {
    const isDisabled = isWeekend(date) || date < addDays(new Date(), 1) || date > addDays(new Date(), 30) || date.toDateString() === today.toDateString();
    return isDisabled;
  };
  
  const calculateEndTime = () => {
    if (!time) return '';

    const [hours, minutes] = time.split(':').map(Number);
    const startDateTime = setMinutes(setHours(date, hours), minutes);
    const endDateTime = addMinutes(startDateTime, meetingDuration);

    return format(endDateTime, 'HH:mm', { locale: fi });
  };

  const isSlotOccupied = (slot) => occupiedSlots.includes(slot);

  const getMessageClass = () => {
    if (message.includes('Syötetyllä sähköpostilla on jo aktiivinen varaus')) return 'error-message';
    if (message.includes('Varausvahvistus')) return 'success-message';
    return '';
  };

  const openModal = (newDate) => {
    setDate(newDate); // Set the selected date
    setStep(newDate ? 2 : 1); // If a date is selected, go directly to step 2 (time picker), else step 1 (calendar)
    setModalIsOpen(true); // Open the modal
  };
  
  
  const closeModal = () => {
    setModalIsOpen(false);
  };  


  return (
    <div className="calendar-container">
      {!showSummary ? (
        <>
          <h4 className="calendar-container-h4">Valitse sopiva päivä aloittaaksesi</h4>
          <span className="calendar-container-span">Konsultaation oletettu kesto on noin 45 minuuttia</span>
          <Calendar
            //onChange={setDate}
            onChange={openModal}
            value={date}
            minDate={minDate}
            maxDate={maxDate}
            className="booking-calendar react-calendar "
            locale="fi"
            tileClassName={({ date }) => {
              const isDisabled = tileDisabled({ date });
              const isToday = date.toDateString() === today.toDateString();
              if (isToday) {
                return 'react-calendar__tile--today';
              }
              return isDisabled ? 'react-calendar__tile--disabled' : (isWeekend(date) ? 'react-calendar__tile--weekend' : null);
            }}
            tileDisabled={tileDisabled}
          />

        <ModalComponent
          isOpen={modalIsOpen}
          onClose={closeModal}
          date={date}
          setDate={setDate}
          time={time}
          setTime={setTime}
          timeBlocks={TIME_BLOCKS}
          occupiedSlots={occupiedSlots}
          //handleBooking={handleBooking}
          step={step}
          setStep={setStep}
          format={format} 
          fi={fi}
        
          email={email}
          setEmail={setEmail}
          companyName={companyName}
          setCompanyName={setCompanyName}
          companyIndustry={companyIndustry}
          setCompanyIndustry={setCompanyIndustry}
          otherSource={otherSource}
          setOtherSource={setOtherSource}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}

          emailError={emailError}
          companyNameError={companyNameError}
          companyIndustryError={companyIndustryError}

          setSubmissionStatus={setSubmissionStatus}
          submissionStatus={submissionStatus}
          //handleConfirm={handleConfirm}
          loading={loading}
        />

        </>
      ) : null}
    </div>
  );
}

export default BookingCalendar;
