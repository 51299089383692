import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Calendar from 'react-calendar';
import axios from 'axios';
import { format, addDays, addMonths, startOfDay } from 'date-fns';
import { fi } from 'date-fns/locale'; // Import Finnish locale for date-fns
import '../ModalComponent.css';

const Meet_Icon = '/build/CalendarMedia/Meet_Icon.svg';
const Avatar_Icon = '/build/CalendarMedia/Avatar_Icon.svg';
const Clock_Icon = '/build/CalendarMedia/Clock_Icon.svg';
const Comment_Icon = '/build/CalendarMedia/Comment_Icon.svg';
const Arrow_Icon = '/build/CalendarMedia/Arrow_Left_Primary_Vector_Icon.svg';
const Arrow_White_Icon = '/build/CalendarMedia/Arrow_Left_White_Vector_Icon.svg';

const ModalComponent = ({ 
  isOpen, 
  onClose, 
  date, 
  setDate, 
  setTime, 
  timeBlocks, 
  step,
  setStep,

  setEmail,
  email,
  setCompanyName,
  companyName,
  setCompanyIndustry,
  companyIndustry,
  otherSource,
  setOtherSource,
  selectedSource,
  setSelectedSource,
}) => {
  const today = startOfDay(new Date());

  const [isDateSelected, setIsDateSelected] = useState(false);

  const [time, setTimeState] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const [occupiedSlots, setOccupiedSlots] = useState([]);
  const [timeBlocksLoading, setTimeBlocksLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const [showSummary, setShowSummary] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [companyIndustryError, setCompanyIndustryError] = useState('');

  const [headerVisible, setHeaderVisible] = useState(false);

  const [showInputFields, setShowInputFields] = useState(true);

  const [message, setMessage] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [bookingConfirmed, setBookingConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const fetchOccupiedSlots = useCallback(async (selectedDate) => {
    setTimeBlocksLoading(true);

    const formattedDate = formatDate(selectedDate);
    try {
      const response = await axios.get(`https://sivubisnes.net/api/occupied-slots?date=${formattedDate}`);
        
        if (response.data && Array.isArray(response.data)) {
            return response.data;
        } else {
            throw new Error('Unexpected response format');
        }
    } catch (error) {
        console.error('Error fetching occupied slots:', error);
        setLoadingError(true);
        return [];
    }
}, []);

useEffect(() => {
  const updateOccupiedSlots = async () => {
    if (date) {
      setTimeBlocksLoading(true);
      setLoadingError(false);
      const slots = await fetchOccupiedSlots(date);
      setOccupiedSlots(slots);
      setTimeBlocksLoading(false);
    }
  };

  updateOccupiedSlots();
}, [date, fetchOccupiedSlots]);

useEffect(() => {
  if (occupiedSlots.length > 0) {
    setTimeBlocksLoading(false);
  }
}, [occupiedSlots]);

const handleBooking = () => {
  setShowSummary(true);
};

  useEffect(() => {
    if (submissionStatus && submissionStatus.type === 'success') {
      setShowInputFields(false);
    }
  }, [submissionStatus]);

  const handleToggle = () => {
    setIsContentVisible(prevState => !prevState);
  };

  const handleTimeSelect = (block) => {
    if (!isSlotOccupied(block)) {
      setTimeState(block);
      setAnimate(false);
      setTimeout(() => setAnimate(true), 10);
    }
  };

  const handleRadioChange = (e) => {
    setSelectedSource(e.target.value);
  };

  const finnishMonthNames = [
    'Tammikuu', 'Helmikuu', 'Maaliskuu', 'Huhtikuu', 'Toukokuu',
    'Kesäkuu', 'Heinäkuu', 'Elokuu', 'Syyskuu', 'Lokakuu',
    'Marraskuu', 'Joulukuu',
  ];

  const handleDateSelection = (newDate) => {
    setDate(newDate);
    setIsDateSelected(true);
    setTime(null);
  };

  const handleTimeConfirmation = () => {
    setStep(3);
  };

  const handleChangeStep = () => {
    setStep((prevStep) => Math.max(1, prevStep - 1));
    clearInputFields();
    setTimeState(null);
    setSubmissionStatus(null);
  };

  const clearInputFields = () => {
    setCompanyName('');
    setCompanyIndustry('');
    setEmail('');
    setSelectedSource('');
  
    setLocalErrors({
      email: '',
      companyName: '',
      companyIndustry: '',
    });
  };

  const handleShowTimePicker = () => {
    setStep(2);
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const tileDisabled = ({ date }) => {
    const isDisabled =
      isWeekend(date) || 
      date < addDays(new Date(), 1) || 
      date > addDays(new Date(), 30) || 
      date.toDateString() === today.toDateString();
    return isDisabled;
  };

  const isSlotOccupied = (slot) => occupiedSlots.includes(slot);

  const calculateEndTime = () => {
    if (!time) return '';
    const [hours, minutes] = time.split(':').map(Number);
    const startDateTime = new Date(date);
    startDateTime.setHours(hours, minutes, 0);
    const endDateTime = new Date(startDateTime.getTime() + 45 * 60 * 1000);
    return `${endDateTime.getHours()}:${endDateTime.getMinutes().toString().padStart(2, '0')}`;
  };



  const [localErrors, setLocalErrors] = useState({
    email: '',
    companyName: '',
    companyIndustry: '',
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const validateFields = () => {
    let isValid = true;
    const errors = {};
  
    if (!companyName.trim()) {
      errors.companyName = 'Yrityksen nimi on pakollinen';
      isValid = false;
    }
  
    if (!companyIndustry.trim()) {
      errors.companyIndustry = 'Yrityksen päätoimiala on pakollinen';
      isValid = false;
    }
  
    if (!validateEmail(email)) {
      errors.email = 'Syötä kelvollinen sähköpostiosoite';
      isValid = false;
    }
  
    setLocalErrors(errors);
  
    return isValid;
  };

  const handleFieldBlur = (field) => {
    const errors = { ...localErrors };

    if (field === 'email' && !validateEmail(email)) {
      errors.email = 'Syötä kelvollinen sähköpostiosoite';
    } else if (field === 'companyName' && !companyName.trim()) {
      errors.companyName = 'Yrityksen nimi on pakollinen';
    } else if (field === 'companyIndustry' && !companyIndustry.trim()) {
      errors.companyIndustry = 'Yrityksen päätoimiala on pakollinen';
    } else {
      errors[field] = '';
    }

    setLocalErrors(errors);
  };

  const handleModalConfirm = () => {
    setSubmissionStatus(null);
    if (validateFields()) {
      handleConfirm(selectedSource, otherSource);
    }
  };

  const handleConfirm = async (source, otherSource) => {
    let hasError = false;

    if (!validateEmail(email)) {
      setEmailError('Pakollinen kenttä');
      hasError = true;
    } else {
      setEmailError('');
    }
    
    if (!companyName) {
      setCompanyNameError('Pakollinen kenttä');
      hasError = true;
    } else {
      setCompanyNameError('');
    }

    if (!companyIndustry) {
      setCompanyIndustryError('Yrityksen päätoimiala on pakollinen');
      hasError = true;
    } else {
      setCompanyIndustryError('');
    }

    if (hasError) return;

    setLoading(true); 
  
    const formattedDate = formatDate(date) + 'T' + time + ':00Z';

    try {
      await axios.post(`https://sivubisnes.net/api/book`, {
        date: formattedDate,
        time,
        email,
        companyName,
        companyIndustry,
        source: source === 'Muu' ? otherSource : source
      });
      setSubmissionStatus({
        type: 'success',
        message: 
        <>
        Onnistui!
        Varausvahvistus on lähetetty sähköpostiisi<br />
        Voit sulkea varausikkunan
        </>
      });
      setBookingConfirmed(true);
    } catch (error) {
      console.error('There was an error making the booking!', error);
      const errorMessage = error.response?.data || 'Varauksen vahvistamisessa tapahtui virhe. Yritä myöhemmin uudelleen.';
      setSubmissionStatus({
        type: 'error',
        message: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  const resetModal = useCallback(() => {
    setStep(1);
    setDate(null);
    setTime(null);
    setEmail('');
    setCompanyName('');
    setCompanyIndustry('');
    setSubmissionStatus(null);
    setSelectedSource('');
  }, [setDate, setTime, setEmail, setCompanyName, setCompanyIndustry, setSelectedSource]);


  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => setHeaderVisible(true), 100);
      return () => clearTimeout(timer);
    } else {
      setHeaderVisible(false);
      resetModal();
      setTimeState(null);
      setTime(null);
      setShowInputFields(true);
    }
  }, [isOpen, submissionStatus, resetModal, setTime]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-left-section desktop">
        <div className={`desktop modal-header ${headerVisible ? 'visible' : ''}`}>
          <h3>Maksuton konsultaatio</h3>
        </div>
          <div className="modal-info-wrapper">
            <div className='modal-info-img-wrapper'>
              <img src={Avatar_Icon} alt="avatar_ikoni" />
            </div>
            <span>Henrik Laakso</span>
          </div>
          <div className="modal-info-wrapper">
            <div className='modal-info-img-wrapper'>
              <img src={Clock_Icon} alt="kello_ikoni" />
            </div>
            <span>45 min</span>
          </div>
          <div className="modal-info-wrapper">
            <div className='modal-info-img-wrapper custom'>
              <img src={Meet_Icon} alt="meet_ikoni" />
            </div>
            <span>Google Meet</span>
          </div>
          <div className="modal-info-wrapper comment-wrapper">
            <div className='modal-info-img-wrapper custom'>
              <img src={Comment_Icon} alt="kommentti_ikoni" />
            </div>
            <p>
              Voit varata maksuttoman konsultaation, joka toteutetaan pääsääntöisesti Google Meetin kautta, mutta myös puhelimitse onnistuu. 
              Konsultaatiossa pidämme noin 45 minuutin aivoriihen liittyen yrityksenne verkkotarpeisiin ja nykyisen verkkonäkyvyytenne tilaan. 
              Halutessanne voimme alkaa jo konsultaatiossa suunnitelemaan yrityksenne verkkomenestyksen avainta.
              Konsultaatio ei sido teitä mihinkään, joten jos yhtään kiinnostaa, niin varaa ihmeessä.
            </p>
          </div>
        </div>
        <div className="modal-right-section">
          {step === 1 && (
            <div className='step-1-wrapper'>
              <div className={`mobile modal-header ${headerVisible ? 'visible' : ''}`}>
                <h3>Maksuton konsultaatio</h3>
              </div>
              <h3 className='calendar-header'>Aloitetaan valitsemalla sopiva päivä</h3>
              <Calendar
                onChange={handleDateSelection}
                value={date}
                minDate={addDays(new Date(), 2)}
                maxDate={addMonths(new Date(), 1)}
                className="modal-calendar react-calendar"
                id="modal"
                locale="fi" // Ensure Finnish locale is applied
                tileClassName={({ date }) => {
                  const isDisabled = tileDisabled({ date });
                  const isToday = date.toDateString() === today.toDateString();
                  if (isToday) {
                    return 'react-calendar__tile--today';
                  }
                  return isDisabled
                    ? 'react-calendar__tile--disabled'
                    : isWeekend(date)
                    ? 'react-calendar__tile--weekend'
                    : null;
                }}
                tileDisabled={tileDisabled}
                formatMonthYear={(locale, date) => finnishMonthNames[date.getMonth()]} // Use custom month names
              />
              {isDateSelected && (
                <button className="search-times-button" onClick={handleShowTimePicker}>
                  Hae aikoja - {format(date, 'dd.MM.yyyy', { locale: fi })}
                </button>
              )}
            </div>
          )}
          {step === 2 && (
          <div className="time-picker-container">
            <button className="change-time-button" 
            onClick={handleChangeStep}
            >
              <img src={Arrow_Icon} alt='Nuoli Ikoni'/>
              <span>Muuta varauspäivä</span>
            </button>
            <h3>
              Valitse sopiva aika päivälle <br /> {format(date, 'dd.MM.yyyy', { locale: fi })}
            </h3>

            {timeBlocksLoading ? (
              <div id="loading-indicator">
                <p>
                  {loadingError ? (
                    <span>Verkkovirhe, yritä uudestaan</span>
                  ) : (
                    <>
                      <span className="hopping-word">Haetaan</span>
                      <span className="hopping-word">aikoja</span>
                      <br />
                      <span className="hopping-word">{format(date, 'dd.MM.yyyy', { locale: fi })}</span>
                    </>
                  )}
                </p>
              </div>
            ) : (
            <div className="time-options">
              {timeBlocks.map((block, index) => (
                <div
                  key={index}
                  className={`time-option-container ${time === block ? 'selected' : ''} ${isSlotOccupied(block) ? 'occupied' : ''}`}
                >
                  <button
                    className="time-option"
                    onClick={() => handleTimeSelect(block)}
                    disabled={isSlotOccupied(block)}
                  >
                    {block}
                  </button>
                  {time === block && (
                    <button
                      key={block}
                      className={`show-summary-button ${animate ? 'slide-in' : ''}`}
                      onClick={handleTimeConfirmation}
                    >
                      Yhteenveto
                    </button>
                  )}
                </div>
              ))}
            </div>
            )}
          </div>
          )}
          {step === 3 && (
            <div className="meeting-details">
              {showInputFields && (
              <button className="change-time-button" 
              onClick={handleChangeStep}
              >
                <img src={Arrow_Icon} alt='Nuoli Ikoni'/>
                <span>Muuta varausaika</span>
              </button>
              )}
              <div className='meeting-details-summary'>
                <div className='meeting-details-summary-section'>
                  <h3>Varauksen yhteenveto</h3>
                  <p>
                    Palaveri varataan päivälle <span className="meeting-detail-highlight">
                      {format(date, 'd. MMMM yyyy', { locale: fi })}
                    </span>
                  </p>
                  <p>Palaverin aloitus <span className="meeting-detail-highlight">{time}</span></p>
                  <p>Palaverin arvioitu päättyminen <span className="meeting-detail-highlight">{calculateEndTime()}</span></p>
                </div>
                
                {showInputFields && (
                <div className='meeting-details-summary-section'>
                  <h3>Tarvitsemme vielä hieman tietoa</h3>
                  <div className={`input-field-wrapper ${localErrors.companyName ? 'invalid' : ''}`}>
                    <label>Yrityksen nimi *</label>
                    <input
                      type="text"
                      placeholder="Syötä yrityksen nimi"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      onBlur={() => handleFieldBlur('companyName')}
                      required
                    />
                  </div>

                  <div className={`input-field-wrapper ${localErrors.companyIndustry ? 'invalid' : ''}`}>
                    <label>Yrityksen päätoimiala *</label>
                    <input
                      type="text"
                      placeholder="Syötä yrityksen päätoimiala"
                      value={companyIndustry}
                      onChange={(e) => setCompanyIndustry(e.target.value)}
                      onBlur={() => handleFieldBlur('companyIndustry')}
                      required
                    />
                  </div>

                  <div className={`input-field-wrapper ${localErrors.email ? 'invalid' : ''}`}>
                    <label>Syötä sähköpostiosoite saadaksesi varausvahvistus *</label>
                    <input
                      type="email"
                      placeholder="Syötä sähköpostiosoite"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={() => handleFieldBlur('email')}
                      required
                    />
                  </div>
                  <div className="input-field-wrapper">
                    <label>Mistä löysit palvelumme? (Valinnainen)</label>
                    <div className="radio-options">
                      <label className="custom-radio">
                        <input
                          type="radio"
                          name="source"
                          value="Google Haku"
                          checked={selectedSource === 'Google Haku'}
                          onChange={handleRadioChange}
                        />
                        <span className="radio-custom"></span> Google Haku
                      </label>
                      <label className="custom-radio">
                        <input
                          type="radio"
                          name="source"
                          value="Suusanallisesti"
                          checked={selectedSource === 'Suusanallisesti'}
                          onChange={handleRadioChange}
                        />
                        <span className="radio-custom"></span> Suusanallisesti
                      </label>
                      <label className="custom-radio">
                        <input
                          type="radio"
                          name="source"
                          value="LinkedIn"
                          checked={selectedSource === 'LinkedIn'}
                          onChange={handleRadioChange}
                        />
                        <span className="radio-custom"></span> LinkedIn
                      </label>
                      <label className="custom-radio">
                        <input
                          type="radio"
                          name="source"
                          value="Muu"
                          checked={selectedSource === 'Muu'}
                          onChange={handleRadioChange}
                        />
                        <span className="radio-custom"></span> Muu
                      </label>
                    </div>
                  </div>

                  {selectedSource === 'Muu' && (
                    <div className="input-field-wrapper">
                      <label>Muu lähde?</label>
                      <input
                        type="text"
                        placeholder="Kirjoita lähde"
                        value={otherSource}
                        onChange={(e) => setOtherSource(e.target.value)}
                      />
                    </div>
                  )}

                </div>
                )}
              </div>
              {submissionStatus && (
                <div className={`submission-status ${submissionStatus.type}`}>
                  <p>{submissionStatus.message}</p>
                </div>
              )}

              {showInputFields && (
                <>
                  <button
                    className="confirm-meeting-button"
                    onClick={handleModalConfirm}
                    disabled={loading}
                  >
                    {loading ? 'Käsitellään...' : 'Vahvista varaus'}
                  </button>
                </>
              )}
            </div>
          )}
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <div className="modal-left-section mobile">
          <button
            className="modal-left-section-toggle-button"
            onClick={handleToggle}
          >
            <span>{isContentVisible ? 'Sulje Info' : 'Lue Info'}</span>
            <img
              src={Arrow_Icon}
              alt="Nuoli Ikoni"
              className={isContentVisible ? 'rotated' : ''}
            />
          </button>
          <div
            className={`modal-left-section-content ${isContentVisible ? 'expanded' : 'collapsed'}`}
          >
            <div className="modal-info-wrapper">
              <div className="modal-info-img-wrapper">
                <img src={Avatar_Icon} alt="avatar_ikoni" />
              </div>
              <span>Henrik Laakso</span>
            </div>
            <div className="modal-info-wrapper">
              <div className="modal-info-img-wrapper">
                <img src={Clock_Icon} alt="kello_ikoni" />
              </div>
              <span>45 min</span>
            </div>
            <div className="modal-info-wrapper">
              <div className="modal-info-img-wrapper custom">
                <img src={Meet_Icon} alt="meet_ikoni" />
              </div>
              <span>Google Meet</span>
            </div>
            <div className="modal-info-wrapper comment-wrapper">
              <div className="modal-info-img-wrapper custom">
                <img src={Comment_Icon} alt="kommentti_ikoni" />
              </div>
              <p>
                Voit varata maksuttoman konsultaation, joka toteutetaan pääsääntöisesti Google Meetin kautta, mutta myös puhelimitse onnistuu. 
                Konsultaatiossa pidämme noin 45 minuutin aivoriihen liittyen yrityksenne verkkotarpeisiin ja nykyisen verkkonäkyvyytenne tilaan. 
                Halutessanne voimme alkaa jo konsultaatiossa suunnitelemaan yrityksenne verkkomenestyksen avainta.
                Konsultaatio ei sido teitä mihinkään, joten jos yhtään kiinnostaa, niin varaa ihmeessä.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ModalComponent;
